dnav = {
    settings: {
        openMenuCheck: false,
        navItems: document.querySelectorAll('.nav-button-title')
    },
    init: function () {
        var navItems = document.querySelectorAll( '.js-nav-item' );

        window.addEventListener( 'DOMContentLoaded', dnav.checkScreenSize );
        window.addEventListener( 'resize', dnav.checkScreenSize );
        

            for ( var index = 0; index < navItems.length; ++index ) {
                dnav.initNavItem( navItems[ index ] )
            }

            [...dnav.settings.navItems].forEach(function(item) {
                item.onclick = function(e){
                    dnav.removeMobileNav()
                }
            }); 

    },
    removeMobileNav: function(){
        document.body.classList.remove( 'body-overflow' )
    },
    initNavItem: function ( navItem ) {
        if ( !!navItem.querySelector( '.js-nav-subnav' ) ) {
            navItem.addEventListener( 'mouseover', dnav.openMenu )
            navItem.addEventListener( 'mouseleave', dnav.collapseMenu )
        }
    },
    initOverlay: function () {
        if ( !dnav.checkOverlay() ) {
            dnav.createOverlay();
        }
    },
    checkOverlay: function () {
        var overlay = document.querySelectorAll( '.js-nav-overlay' )
        if ( overlay.length > 0 ) return true;
    },
    openMenu: function ( event ) {
        event.stopPropagation();

        if ( !dnav.settings.openMenuCheck ) {
            dnav.settings.openMenuCheck = true
            event.currentTarget.classList.add( 'current' );
            event.currentTarget.querySelector( '.js-nav-subnav' ).classList.add( 'current' );
            dnav.initOverlay();
        }
    },
    collapseMenu: function ( event ) {

        event.stopPropagation();

        var e = event.toElement || event.relatedTarget;

        if ( e.parentNode == this || e == this ) {
            return;
        }

        event.currentTarget.classList.remove( 'current' );
        event.currentTarget.querySelector( '.js-nav-subnav' ).classList.remove( 'current' );

        dnav.deleteOverlay();
        dnav.settings.openMenuCheck = false;
    },
    createOverlay: function () {
        var addOverlayDiv = document.createElement( "div" );
        addOverlayDiv.classList.add( 'nav__overlay', 'js-nav-overlay' )
        document.body.appendChild( addOverlayDiv );
    },
    deleteOverlay: function () {
        if ( dnav.checkOverlay() ) {
            var deleteOverlayDiv = document.querySelector( '.js-nav-overlay' )
            return document.body.removeChild( deleteOverlayDiv )
        }
    },
    checkScreenSize: function () {
        let screen = document.querySelector( 'body' );
        if ( screen ) {
            if ( window.innerWidth <= 1279 ) {
                return false
            } else {
                return true
            }
        }
    }
}