let mnav = {
    init: function(){ 
      
        jQuery('.js-nav-toggle').click(function(e){
            jQuery('body').toggleClass('body-overflow');
            jQuery('.subnav-wrapper button').removeClass('subnav-active-btn');
            jQuery('.nav-subnav').removeClass('subnav-active');  
        })
         
        jQuery('.subnav-wrapper button').click(function(e){
            if(jQuery(this).hasClass('subnav-active-btn')){ 
                jQuery('.subnav-wrapper button').removeClass('subnav-active-btn');
                jQuery('.nav-subnav').removeClass('subnav-active');
            }else{
                jQuery('.subnav-wrapper button').removeClass('subnav-active-btn');
                jQuery('.nav-subnav').removeClass('subnav-active');
                jQuery(this).addClass('subnav-active-btn');
                jQuery(this).parent().siblings('.nav-subnav').addClass('subnav-active');
            }   
        })

        jQuery(window).resize(function(){
            jQuery('body').removeClass('body-overflow');
            jQuery('.subnav-wrapper button').removeClass('subnav-active-btn');
            jQuery('.nav-subnav').removeClass('subnav-active'); 
        })
    }
}